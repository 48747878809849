@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app,
.content {
  font-family: Source Sance Pro, sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
  margin: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* Color */
:root {
  --primary: #0b0c88;
  --secondary: #1c1de4;
}


.custom-ant-table .ant-table-thead>tr>th {
  background-color: #000;
  /* Ganti dengan warna yang diinginkan */
  color: #fff;
  /* Warna teks untuk kontras */
}

.button-add {
  font-weight: bold;
  /* background-color: var(--primary); */
}

.button-import {
  font-weight: 600;
}

.expand-icon {
  font-size: 16px;
  padding: 1px;
  /* border: 1px solid #dfdfdf;
  border-radius: 4px; */
  color: #303030;
}

.expand-icon:hover {
  color: #72B6FF;
  /* border: 1px solid #72B6FF; */
  cursor: pointer;
}

.detail-description {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.approved-row,
.approved-row:hover {
  background-color: #00ff0050 !important;
}